import React from 'react'
import Module from '../components/Module'
import webm from '../assets/video.webm'
import mp4 from '../assets/video.mp4'
import logo from '../assets/logo.png'

const Home = () => {
  return (
    <>
      <Module type="video">
        <video playsInline autoPlay muted loop poster="/src/assets/video.png" id="video">
          <source src={webm} type="video/webm" />
          <source src={mp4} type="video/mp4" />
        </video>
      </Module>
      <Module type="foreground">
        <nav>
          <img src={logo} alt="all sinners" />
          <ul className="nav-list">
            <li className="nav-list-item">
              <a href="//instagram.com/allsinnersmusic" target="_blank" rel="noreferrer">instagram<span>@allsinnersmusic</span></a>
            </li>
            <li className="nav-list-item">
              <a href="//twitter.com/AllsinnersMusic" target="_blank" rel="noreferrer">twitter<span>@allsinnersmusic</span></a>
            </li>
            <li className="nav-list-item">
              <a href="//www.youtube.com/channel/UCfT_Wyrr9EgxFhVmhGdkLNw" target="_blank" rel="noreferrer">youtube</a>
            </li>
            <li className="nav-list-item">
              <a href="//allsinnersmusic.bandcamp.com" target="_blank" rel="noreferrer">bandcamp<span>@allsinnersmusic</span></a>
            </li>
            <li className="nav-list-item">
              <a href="//www.deezer.com/en/profile/4948645162" target="_blank" rel="noreferrer">deezer</a>
            </li>
          </ul>
        </nav>
      </Module>
    </>
  )
}

export default Home
