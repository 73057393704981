import styled from 'styled-components'

export const StyledModule = styled.section`
  video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    margin-top: 3rem;
    img {
      display: block;
      max-width: 320px;
      width: 100%;
      margin-bottom: 1rem;
    }
    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      @media (min-width: 640px) {
        flex-direction: row;
      }
    }
    li {
      padding: 2rem 0;
      margin: 0 1rem;
      text-align: center;
      a {
        position: relative;
        display: inline-block;
        font-family: 'Libre Bodoni', serif;
        font-size: 3rem;
        color: white;
        letter-spacing: -0.05em;
        /* transform: rotate(-2deg); */
        transform-origin: center center;
        &:hover {
          opacity: 0.8;
        }
        span {
          display: block;
          position: absolute;
          /* bottom: -1em; */
          right: 0;
          font-family: monospace;
          font-size: 0.5rem;
          pointer-events: none;
          letter-spacing: 0;
          /* transform: rotate(5deg); */
          transform-origin: center center;
        }
      }
    }
  }
`
