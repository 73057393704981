import React from 'react'
import { StyledModule } from './styles.js'

const Module = ({ title = '', type = 'default', className = '', id = '', children }) => {
  return (
    <StyledModule id={id} className={`module module-${type} ${className} ui-container`}>
      {
        title &&
        <div className="module-title">
          { title }
        </div>
      }
      <div className="module-content">
        { children }
      </div>
    </StyledModule>
  )
}

export default Module
