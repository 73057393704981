import React from 'react'
// import AppManager from './components/App/Manager'
// import Header from './components/Header'
// import Footer from './components/Footer'
import Home from './pages/Home'

const App = () => {
  return (
    <Home />
  )
}

export default App
